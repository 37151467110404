import React from 'react';
import {
  Container, Row, Col
} from 'react-bootstrap';
import Text from 'components/common/Text';
import Card from 'components/common/cards/Card';
import Tooltip from 'components/common/Tooltip';
import getNested from 'utility/getNested';
import colors from 'utility/colors';
import Icon from 'components/common/Icon';

const Spacer = ({
  color=colors.PRIMARY
}) => {
  return <div style={{width: 1, borderColor: color, borderWidth: 1, borderStyle: 'solid', marginRight: 20, marginLeft: 20, marginTop: 3, marginBottom: 3}}/>
}

const DataCard = ({
  data=[],
  title='',
  cardColor=colors.WHITE,
  textColor=colors.PRIMARY,
  titleColor=colors.WHITE,
  tooltipCopy,
  cardStyle={},
  titleStyle={},
  valueStyle={},
  noShadow=false
}) => {
  return <>
    {title && 
      <Text size={14} style={{fontWeight: 'bold', color: titleColor, marginBottom: 0, paddingBottom: 3}}>
        {title}

        {tooltipCopy 
          ? <Tooltip className={'toolTipMobile'} text={tooltipCopy}>
          <Icon icon='info-circle' 
            color={titleColor} 
            size={10} 
            style={{marginTop: -15, marginLeft: 3}}/>
        </Tooltip>
          : null}
        
      </Text>}

    <Card noShadow={noShadow} style={{padding: 15, paddingLeft: 20, paddingRight: 20, backgroundColor: cardColor, ...cardStyle}}>
      <Row style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        {data && data.map((dataObj, index) => {
          if(getNested(dataObj, 'hide'))
            return null

          let _valueStyle = {
            ...getNested(dataObj, 'valueStyle'),
            ...valueStyle
          }

          return <Col key={index} style={{justifyContent: 'space-between', marginTop: 5, marginBottom: 5}}>
            <Text size={12} style={{fontSize: 12, color: textColor, margin: 0, marginBottom: 0, whiteSpace: 'nowrap', ...titleStyle}}>
              {getNested(dataObj, 'title')}
            </Text>
            <Text size={12} style={{fontSize: 22, color: textColor, lineHeight: 1, margin: 0, fontWeight: 'bold', whiteSpace: 'nowrap', ..._valueStyle}}>
              {getNested(dataObj, 'value')}
            </Text>
          </Col>
        })}
      </Row>
    </Card>
  </>
}

export default DataCard