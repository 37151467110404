import React from 'react'
import styled from 'styled-components'
import { Row, Col, Modal } from 'react-bootstrap'
import { FaTimes } from 'react-icons/fa'
import Text from 'components/common/Text'
import Card from 'components/common/cards/Card'
import Button from 'components/common/Button'
import { COLORS } from 'utility/colors'
import { useTenantProfile } from 'hooks/api/tenant/useTenantProfile'

const CloseButton = styled(FaTimes)`
	position: absolute;
	top: 5px;
	right: 5px;
	background-color: 'white';
	padding: 5px;
	:hover {
		cursor: pointer;
	}
`

const StyledModal = styled(Modal)`
	display: flex !important;
	min-width: 200px;
	width: 100%;
	justify-content: center !important;
	align-items: flex-start !important;
`

const InternalModal = ({
	open,
	style = {},
	cardStyle = {},
	title,
	size = 'lg',
	subtitle,
	body,
	children,
	setClose = () => null,
	onSave = () => null,
	buttons,
	showCancel = true,
	cancelCopy = 'Cancel',
	saveCopy = 'Save',
	showSave = true,
	saveDisabled = false,
	saveColor,
	cancelColor = COLORS.WHITE,
	cancelCopyColor = COLORS.BLACK,
}) => {
	const { data: tenantProfile } = useTenantProfile()
	const primaryColor = tenantProfile?.theme?.primaryColor

	if (!saveColor) {
		saveColor = primaryColor
	}

	return (
		<StyledModal
			size={size}
			style={style}
			onHide={setClose} // This is a controlled component, so ignore this function callback
			show={open ? true : false}
		>
			<Card
				style={{ marginBottom: 0, width: '100%', ...cardStyle }}
				title={title}
				subtitle={subtitle}
			>
				{body && <Text>{body}</Text>}
				{children && <div className='toolTipModal'>{children}</div>}
				{showCancel || showSave ? (
					<Row style={{ marginTop: 20 }}>
						{buttons ? buttons : null}
						{showCancel && (
							<Col xs={{ span: showSave ? 6 : 12 }}>
								<Button color={cancelCopyColor} background={cancelColor} onClick={setClose}>
									{cancelCopy}
								</Button>
							</Col>
						)}
						{showSave && (
							<Col xs={{ span: showCancel ? 6 : 12 }}>
								<Button
									disabled={saveDisabled}
									background={primaryColor}
									onClick={() => onSave()}
									style={{
										backgroundColor: !saveDisabled && saveColor,
									}}
								>
									{saveCopy}
								</Button>
							</Col>
						)}
					</Row>
				) : null}
			</Card>
			<CloseButton size={30} onClick={setClose} />
		</StyledModal>
	)
}

export default InternalModal
